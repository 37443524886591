import { NavLink } from "react-router-dom";
import "../../App.scss";
import React, { useState, useEffect } from "react";
import { Nav, BurgerMenu } from "./Navigation.jsx";

export const Header = (props) => {
  let [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    // You can set the currentPage state based on the current page
    setCurrentPage(window.location.pathname);
  }, []);

  const handleClick = () => {
    setCurrentPage(window.location.pathname);
  };

  console.log(currentPage);
  return (
    <header
      onClick={handleClick}
      className={`${currentPage === "/" ? "HomePage" : "Basic"} ${
        currentPage === "/contact" ? "ContactPage" : "Basic"
      }`}
    >
      <NavLink to="/">
        <svg
          version="1.1"
          id="MobileLogo"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 170.1 53.9"
          xmlSpace="preserve"
        >
          <g>
            <path
              className="st0"
              d="M60.7,33.7c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0.1c0,0.3-0.1,0.7-0.1,1c0,0.3-0.1,0.6-0.1,0.9
		c-0.1,0.7-0.3,1.4-0.4,2.1c-4.7,17.2-30.3,22.1-38.6,3.8c1.7-0.3,3.5-0.5,5.2-0.7c5.1,10.8,24.6,8.7,27.8-3.1v0c0,0,0,0,0,0
		c0.6-1.5,1.1-3.1,1.2-4.7c0-0.1,0-0.1,0-0.2c0-2.5-0.8-4.9-2.1-6.8c-2-2.7-5.2-4.2-9.1-2.4c-1.3,0-4.9,0-6.2,0
		c5.5-10.4,17.4-5.8,21.3,2.4C60.5,28.9,61,31.3,60.7,33.7z"
            />
            <path
              className="st0"
              d="M150.6,53.9H92.1c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.6,2.5-2.6l53.1-0.2c-0.3-2.3-0.9-5.9-2.2-10.2h0
		c-1.1-3.6-2.9-7.9-5.3-11.7c-8.6-13.8-22.9-21-42.5-21.6l0,19.2H88c-10.7-18-53.1-32.2-62-0.8c-1.7-0.2-3.4-0.4-5.1-0.7
		c8.9-34.7,54.4-24.8,69.3-4l0-18.9c32,0,46.8,13.5,53.5,26.7c2.2,4.1,3.7,8.1,4.8,11.7h0C150.9,48.4,150.4,49.6,150.6,53.9z"
            />
            <path
              className="st0"
              d="M125.6,30.6c0-0.3-0.1-0.5-0.2-0.8c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1
		c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.5-0.3-0.8
		c-0.1-0.3-0.3-0.6-0.4-0.9c-4.3-7.7-16-7.2-20.2,0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.3-0.2,0.5-0.3,0.8
		c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.4-0.1,0.6
		c0,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0.1,0.9c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6
		c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6
		c4.2,7.7,16.1,7.2,20.2,0c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0.1-0.5c0-0.2,0-0.3,0-0.5
		C125.8,32.6,125.7,31.6,125.6,30.6z M118.5,35.9c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.5,0.2-1.1,0.4-1.7,0.4c-0.2,0-0.4,0-0.6,0h-0.2
		c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.2,0-0.3-0.1c-0.7-0.2-1.3-0.5-1.9-0.9c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.2-0.2-0.5-0.5-0.7-0.8c-0.7-0.8-1.1-2-1.1-3.4c0-2,0.8-3.4,2.1-4.3c0.2-0.2,0.4-0.3,0.7-0.5c0.1-0.1,0.2-0.1,0.3-0.2
		c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
		c0.2,0,0.4,0,0.6,0s0.4,0,0.6,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.4,0.1,0.7,0.2,1,0.4
		c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4c1.2,0.9,2,2.3,2.1,4.3C119.7,34,119.2,35.1,118.5,35.9z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M170.1,32.6c0,3.2-2.5,5.8-5.7,5.9h-16c-1-3.6-2.5-7.6-4.8-11.7h20.6c3,0,5.5,2.3,5.8,5.3
		C170.1,32.2,170.1,32.4,170.1,32.6z"
            />
            <path
              className="st1"
              d="M103.8,37.9c0.1,0.2,0.2,0.4,0.3,0.6H60c0.2-0.7,0.3-1.4,0.4-2.1c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0.1-0.7,0.1-1
		c0,0,0,0,0-0.1c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1c0.3-2.4-0.2-4.8-1.2-6.9h44.6c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1
		c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0.1,0.9c0,0,0,0.1,0,0.1
		c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.7
		C103.6,37.4,103.7,37.7,103.8,37.9z"
            />
            <path
              className="st1"
              d="M124.3,38.5c1.2-2.1,1.8-4.7,1.3-7.8c-0.3-1.5-0.7-2.8-1.4-3.9h13.5c2.4,3.8,4.2,8.1,5.3,11.7H124.3z"
            />
            <path
              className="st1"
              d="M55.6,33.6c0,0.1,0,0.1,0,0.2c-0.1,1.6-0.6,3.2-1.2,4.7c0,0,0,0,0,0c-12.2-0.3-26.2,0.3-38.2,1.3L0,32.6
		l16.2-7.2c11.7,1,25.3,1.7,37.3,1.3C54.8,28.6,55.6,31.1,55.6,33.6z"
            />
          </g>
        </svg>

        <svg
          id="Title"
          // className={currentPage === 'Home' ? 'home-page' : 'other-page'}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 447.3 139.7"
          xmlSpace="preserve"
        >
          <g>
            <path
              className="st5"
              d="M54,32.8c0,3.6-0.6,6.9-1.7,9.9c-1.1,3-2.8,5.5-4.9,7.7c-2.1,2.1-4.7,3.8-7.7,5c-3.1,1.2-6.5,1.8-10.3,1.8
		H13.8v25.1H0V8.5h13.8h15.7c3.8,0,7.3,0.6,10.3,1.7c3,1.1,5.6,2.7,7.7,4.8c2.1,2.1,3.7,4.6,4.8,7.6C53.5,25.6,54,29,54,32.8z
		 M39.5,32.5c0-3.3-1-6.1-3.1-8.3c-2.1-2.3-5.2-3.4-9.5-3.4H13.8v24.4h13.1c4.2,0,7.4-1.2,9.5-3.6C38.5,39.2,39.5,36.2,39.5,32.5z"
            />
            <path
              className="st5"
              d="M91.3,82.2c-0.1-0.7-0.3-1.5-0.3-2.4c-0.1-0.9-0.2-1.8-0.4-2.7c-1.3,1.9-3.3,3.4-5.8,4.6
		c-2.5,1.2-5.5,1.8-9,1.8c-5.4,0-9.8-1.4-13.1-4.3c-3.3-2.9-5-6.8-5-11.9s1.6-8.9,4.8-11.6c3.2-2.7,7.6-4.1,13.3-4.1h14.5V50
		c0-3-0.9-5.2-2.7-6.5c-1.8-1.3-4.2-2-7.2-2c-5.5,0-9.5,2.3-11.9,7L59,43c4.3-8.1,11.7-12.2,22.2-12.2c6.4,0,11.6,1.6,15.7,4.9
		c4.1,3.3,6.1,8.7,6.1,16.1V71c0,2.4,0.1,4.7,0.3,6.8c0.2,2.1,0.4,3.6,0.7,4.4H91.3z M90.4,60.6H78.9c-2.6,0-4.5,0.6-5.9,1.8
		c-1.4,1.2-2.1,2.8-2.1,4.8c0,2.1,0.7,3.7,2.2,4.8c1.4,1.1,3.5,1.7,6.2,1.7c1.5,0,2.9-0.2,4.2-0.7c1.3-0.4,2.5-1.1,3.5-2.1
		c1-1,1.8-2.2,2.4-3.7c0.6-1.5,0.9-3.4,0.9-5.6V60.6z"
            />
            <path
              className="st5"
              d="M139.1,43.2h-9.6v39h-12.9v-39H107V32.4h9.7V16.9h12.9v15.5h9.6V43.2z"
            />
            <path
              className="st5"
              d="M170.8,31.7c1.8,0,3.8,0.2,5.8,0.7v12.1c-1.8-0.5-3.7-0.7-5.6-0.7c-4.2,0-7.5,1.3-9.8,3.9
		c-2.3,2.6-3.5,6.3-3.5,11v23.6h-13.2V32.5h12.1l0.7,5.7c1.1-2.1,2.8-3.8,5.3-4.9C165,32.2,167.7,31.7,170.8,31.7z"
            />
            <path
              className="st5"
              d="M182.1,15.4c0-2.2,0.8-4.1,2.3-5.6c1.5-1.5,3.4-2.3,5.6-2.3c2.2,0,4.1,0.8,5.6,2.3c1.5,1.5,2.3,3.4,2.3,5.6
		c0,2.3-0.8,4.2-2.3,5.7c-1.5,1.5-3.4,2.3-5.6,2.3c-2.2,0-4.1-0.8-5.6-2.3C182.9,19.5,182.1,17.6,182.1,15.4z M196.7,32.4v49.9
		h-13.2V32.4H196.7z"
            />
            <g>
              <g>
                <path
                  className="st4"
                  d="M316.4,44c0.1,5.6-0.9,10.7-2.8,15.5c-2,4.7-4.7,8.8-8.2,12.2c-3.5,3.4-7.6,6.1-12.4,8
				c-4.8,1.9-9.8,2.8-15.3,2.7h-27.1V5.5h27.1c5.4-0.1,10.5,0.9,15.2,2.8c4.7,1.9,8.8,4.6,12.4,8.1s6.3,7.5,8.3,12.3
				S316.5,38.4,316.4,44z M301.3,44c0-3.7-0.6-7.1-1.7-10.2c-1.2-3.1-2.8-5.7-4.8-8c-2.1-2.2-4.5-4-7.4-5.2
				c-2.9-1.2-6.1-1.8-9.7-1.8H265v50.3h12.7c3.6,0,6.8-0.6,9.7-1.8c2.9-1.2,5.4-2.9,7.5-5.1c2.1-2.2,3.7-4.8,4.8-7.9
				C300.8,51.1,301.3,47.7,301.3,44z"
                />
                <path
                  className="st4"
                  d="M356.5,82.3c-0.1-0.8-0.3-1.6-0.4-2.5c-0.1-0.9-0.2-1.8-0.4-2.8c-1.4,2-3.4,3.6-6.1,4.8
				c-2.7,1.3-5.8,1.9-9.3,1.9c-5.7,0-10.2-1.5-13.7-4.5c-3.5-3-5.2-7.1-5.2-12.4s1.7-9.3,5-12.1c3.3-2.8,8-4.2,13.8-4.2h15.1v-1.8
				c0-3.1-0.9-5.4-2.8-6.8c-1.9-1.4-4.3-2.1-7.5-2.1c-5.7,0-9.8,2.4-12.4,7.3l-10-5.7c4.5-8.4,12.2-12.7,23.1-12.7
				c6.6,0,12.1,1.7,16.3,5.1s6.4,9,6.4,16.8v19.9c0,2.5,0.1,4.9,0.3,7.1c0.2,2.2,0.4,3.8,0.7,4.6H356.5z M355.6,59.8h-12
				c-2.7,0-4.7,0.6-6.2,1.8c-1.4,1.2-2.2,2.9-2.2,5c0,2.2,0.8,3.8,2.3,5c1.5,1.2,3.7,1.8,6.5,1.8c1.5,0,3-0.2,4.4-0.7
				c1.4-0.5,2.6-1.2,3.7-2.2c1-1,1.9-2.3,2.5-3.9c0.6-1.6,0.9-3.5,0.9-5.8V59.8z"
                />
                <path
                  className="st4"
                  d="M426.1,50.6v31.7h-13.7V53.7c0-4.9-1.1-8.2-3.2-9.8c-2.2-1.7-4.7-2.6-7.5-2.6c-3.1,0-5.8,1-8.3,2.9
				c-2.5,1.9-3.8,5.1-3.8,9.6v28.5h-13.6V4h13.7v31.2c1.5-2,3.6-3.6,6.2-4.8c2.6-1.2,5.5-1.7,8.6-1.7c7,0,12.4,1.9,16.1,5.7
				C424.2,38.2,426.1,43.6,426.1,50.6z"
                />
                <path className="st4" d="M433.6,4h13.7v78.2h-13.7V4z" />
              </g>
              <circle className="st4" cx="263.2" cy="117.2" r="5.7" />
            </g>
            <g>
              <path
                className="st5"
                d="M202.6,82.2V7.1h13.2v44.7l18.4-19.4h16.4L229,55.2l20.3,27.1h-16l-13-18l-4.5,4.8v13.2H202.6z"
              />
              <g className="st9">
                <path
                  className="st5"
                  d="M26.8,99v29.7h-2.1v-25.1l-10.3,25.1h-2L2,103.7v25H0V98.9h2l11.4,27.3l11.3-27.3L26.8,99z"
                />
                <path
                  className="st5"
                  d="M51.7,119.8H34.6c0.2,2.5,1,4.3,2.3,5.6c1.4,1.3,3.1,1.9,5.2,1.9c1.7,0,3.1-0.4,4.3-1.2s2.1-1.9,2.7-3.3
				l1.7,0.8c-0.9,1.8-2.1,3.1-3.6,4.1c-1.5,1-3.3,1.4-5.2,1.4c-1.4,0-2.7-0.3-3.9-0.8c-1.2-0.5-2.1-1.2-3-2.1
				c-0.8-0.9-1.4-2-1.9-3.3c-0.4-1.3-0.7-2.7-0.7-4.3c0-1.5,0.2-3,0.7-4.3c0.4-1.3,1.1-2.4,1.9-3.4c0.8-1,1.8-1.7,3-2.2
				c1.2-0.5,2.5-0.8,4-0.8c1.4,0,2.7,0.2,3.9,0.7c1.2,0.5,2.1,1.2,3,2.1c0.8,0.9,1.5,2,1.9,3.3c0.5,1.3,0.7,2.7,0.7,4.3V119.8z
				 M49.7,118.2c-0.1-2.7-0.8-4.8-2.2-6.2c-1.4-1.4-3.1-2.2-5.4-2.2c-2.2,0-4,0.7-5.4,2.2c-1.4,1.5-2.1,3.5-2.2,6.2H49.7z"
                />
                <path
                  className="st5"
                  d="M73,128.7c-0.1-0.6-0.2-1.3-0.2-2c-0.1-0.7-0.1-1.4-0.1-2.1c-0.8,1.5-1.8,2.7-3.2,3.4
				c-1.4,0.8-2.9,1.2-4.7,1.2c-1.5,0-2.8-0.3-3.9-0.8c-1.1-0.5-2.1-1.3-2.9-2.2c-0.8-0.9-1.4-2.1-1.8-3.4c-0.4-1.3-0.6-2.7-0.6-4.2
				c0-1.5,0.2-2.9,0.6-4.2c0.4-1.3,1-2.4,1.8-3.4c0.8-1,1.8-1.7,2.9-2.2c1.1-0.5,2.4-0.8,3.9-0.8c1.8,0,3.3,0.4,4.7,1.2
				c1.4,0.8,2.5,1.9,3.2,3.5V98.4h1.9v25.9c0,0.9,0.1,1.7,0.1,2.4c0.1,0.7,0.1,1.4,0.2,1.9H73z M72.4,118.6c0-1.3-0.2-2.5-0.5-3.6
				c-0.3-1.1-0.8-2-1.5-2.8c-0.6-0.8-1.4-1.4-2.4-1.8c-0.9-0.4-2-0.6-3.2-0.6s-2.2,0.2-3.1,0.6c-0.9,0.4-1.7,1-2.3,1.8
				c-0.6,0.8-1.1,1.7-1.5,2.8c-0.3,1.1-0.5,2.3-0.5,3.6c0,1.3,0.2,2.5,0.5,3.6c0.3,1.1,0.8,2,1.5,2.8c0.6,0.8,1.4,1.4,2.3,1.8
				c0.9,0.4,2,0.6,3.2,0.6s2.2-0.2,3.2-0.6c0.9-0.4,1.7-1,2.4-1.8c0.6-0.8,1.1-1.7,1.5-2.8C72.2,121.1,72.4,119.9,72.4,118.6z"
                />
                <path
                  className="st5"
                  d="M80.4,101c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.4-0.6C80.6,102.1,80.4,101.6,80.4,101z M83.5,108.6v20.1h-1.9
				v-20.1H83.5z"
                />
                <path
                  className="st5"
                  d="M108,119.8H90.9c0.2,2.5,1,4.3,2.3,5.6c1.4,1.3,3.1,1.9,5.2,1.9c1.7,0,3.1-0.4,4.3-1.2
				c1.2-0.8,2.1-1.9,2.7-3.3l1.7,0.8c-0.9,1.8-2.1,3.1-3.6,4.1c-1.5,1-3.3,1.4-5.2,1.4c-1.4,0-2.7-0.3-3.9-0.8
				c-1.2-0.5-2.1-1.2-3-2.1c-0.8-0.9-1.4-2-1.9-3.3c-0.4-1.3-0.7-2.7-0.7-4.3c0-1.5,0.2-3,0.7-4.3c0.4-1.3,1.1-2.4,1.9-3.4
				c0.8-1,1.8-1.7,3-2.2c1.2-0.5,2.5-0.8,4-0.8c1.4,0,2.7,0.2,3.9,0.7c1.2,0.5,2.1,1.2,3,2.1s1.5,2,1.9,3.3c0.5,1.3,0.7,2.7,0.7,4.3
				V119.8z M106,118.2c-0.1-2.7-0.8-4.8-2.2-6.2c-1.4-1.4-3.1-2.2-5.4-2.2c-2.2,0-4,0.7-5.4,2.2c-1.4,1.5-2.1,3.5-2.2,6.2H106z"
                />
                <path
                  className="st5"
                  d="M130.8,131.9c0,3.9-3.3,5.8-9.8,5.8c-2.5,0-4.7-0.4-6.6-1.1c-1.9-0.7-2.8-2.1-2.8-4.2c0-1,0.3-1.9,0.8-2.7
				c0.5-0.8,1.3-1.4,2.2-1.9c-0.6-0.2-1.1-0.6-1.4-1.1c-0.3-0.5-0.5-1.1-0.5-1.7c0-0.8,0.2-1.5,0.7-2.1c0.5-0.6,1.1-1,2-1.2
				c-0.9-0.7-1.6-1.5-2.1-2.6c-0.5-1-0.7-2.1-0.7-3.3c0-1.1,0.2-2.1,0.6-3.1c0.4-1,1-1.8,1.7-2.5c0.7-0.7,1.6-1.2,2.6-1.7
				c1-0.4,2.1-0.6,3.3-0.6c1.8,0,3.4,0.4,4.7,1.3c0.9-1.6,2.5-2.3,4.7-2.1v2c-0.1,0-0.3,0-0.6,0c-1.2,0-2.1,0.4-2.8,1.2
				c0.7,0.7,1.3,1.5,1.7,2.5c0.4,1,0.6,2,0.6,3.1c0,1.1-0.2,2.1-0.6,3c-0.4,0.9-1,1.7-1.7,2.4c-0.7,0.7-1.6,1.2-2.6,1.6
				c-1,0.4-2.1,0.6-3.3,0.6c-1.4,0-2.7-0.3-4-0.8c-1.7,0-2.5,0.7-2.5,1.9c0,0.6,0.2,1,0.5,1.4c0.3,0.3,0.9,0.5,1.7,0.5h7.8
				c2,0,3.6,0.5,4.7,1.4C130.2,128.9,130.8,130.2,130.8,131.9z M128.8,131.8c0-1.1-0.4-2-1.2-2.6c-0.8-0.6-1.9-1-3.3-1h-6.9
				c-1.4,0-2.4,0.4-3.1,1.1c-0.7,0.7-1.1,1.6-1.1,2.6c0,1.6,0.8,2.7,2.3,3.2c1.5,0.5,3.3,0.7,5.3,0.7c2.5,0,4.5-0.3,5.9-0.9
				C128.1,134.5,128.8,133.4,128.8,131.8z M120.7,121.8c2,0,3.6-0.6,4.8-1.7c1.1-1.1,1.7-2.5,1.7-4.3c0-0.9-0.2-1.7-0.5-2.5
				s-0.7-1.4-1.3-1.9c-0.6-0.6-1.2-1-2-1.3c-0.8-0.3-1.7-0.5-2.6-0.5c-1,0-1.9,0.2-2.7,0.5c-0.8,0.3-1.5,0.7-2,1.3
				c-0.6,0.6-1,1.2-1.3,1.9c-0.3,0.7-0.5,1.6-0.5,2.5c0,1.8,0.6,3.2,1.7,4.3C117.2,121.3,118.7,121.8,120.7,121.8z"
                />
                <path
                  className="st5"
                  d="M142.4,108.3c0.7,0,1.4,0.1,2.1,0.3v1.8c-0.7-0.2-1.4-0.3-2.1-0.3c-1.8,0-3.2,0.6-4.1,1.7
				c-1,1.1-1.5,2.6-1.5,4.5v12.5h-1.9v-20.1h1.8l0.1,2.8c0.4-1,1.2-1.7,2.2-2.2C140,108.6,141.2,108.3,142.4,108.3z"
                />
                <path
                  className="st5"
                  d="M162,128.7c-0.2-0.8-0.3-2-0.4-3.6c-0.6,1.3-1.6,2.3-2.9,3c-1.3,0.7-2.9,1.1-4.6,1.1c-2.3,0-4.1-0.6-5.4-1.7
				c-1.3-1.1-2-2.6-2-4.5c0-1.9,0.7-3.5,2-4.6c1.3-1.2,3.1-1.7,5.4-1.7h7.5v-1c0-3.9-2-5.8-5.9-5.8c-1.7,0-3.1,0.3-4.1,1
				c-1.1,0.7-1.9,1.6-2.4,2.7l-1.6-1c0.7-1.4,1.7-2.5,3.1-3.3c1.4-0.8,3-1.2,5-1.2c2.4,0,4.3,0.6,5.7,1.8c1.4,1.2,2.1,3,2.1,5.5v9.7
				c0,0.6,0,1.3,0.1,2c0.1,0.7,0.1,1.3,0.3,1.6L162,128.7z M161.6,118.4h-7.2c-1.8,0-3.3,0.4-4.3,1.2c-1,0.8-1.5,1.9-1.5,3.4
				s0.5,2.5,1.5,3.3s2.3,1.1,4,1.1c2.4,0,4.3-0.8,5.5-2.3c1.3-1.5,1.9-3.6,1.9-6V118.4z"
                />
                <path
                  className="st5"
                  d="M183.8,108.6v20.1h-1.9v-18.5h-9.4v18.5h-1.9v-18.5h-3.1v-1.6h3.1V104c0-1.9,0.5-3.3,1.5-4.2
				c1-0.9,2.2-1.4,3.7-1.4c0.4,0,1,0.1,1.7,0.2v1.8c-0.5-0.1-1-0.1-1.5-0.1c-1.3,0-2.1,0.3-2.7,1c-0.5,0.6-0.8,1.6-0.8,2.8v4.5
				H183.8z M184.8,101c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.4-0.9,0.6-1.5,0.6s-1.1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4
				c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.4-0.6s1.1,0.2,1.5,0.6C184.7,99.9,184.8,100.4,184.8,101z"
                />
                <path
                  className="st5"
                  d="M190.9,128.7V98.4h1.9v21.4l10.9-11.3h2.5l-8.3,8.6l8.4,11.5h-2.2l-7.5-10.2l-3.8,3.9v6.3H190.9z"
                />
                <path
                  className="st5"
                  d="M226.8,119.8h-17.1c0.2,2.5,1,4.3,2.3,5.6c1.4,1.3,3.1,1.9,5.2,1.9c1.7,0,3.1-0.4,4.3-1.2
				c1.2-0.8,2.1-1.9,2.7-3.3l1.7,0.8c-0.9,1.8-2.1,3.1-3.6,4.1c-1.5,1-3.3,1.4-5.2,1.4c-1.4,0-2.7-0.3-3.9-0.8
				c-1.2-0.5-2.1-1.2-3-2.1c-0.8-0.9-1.4-2-1.9-3.3c-0.4-1.3-0.7-2.7-0.7-4.3c0-1.5,0.2-3,0.7-4.3c0.4-1.3,1.1-2.4,1.9-3.4
				c0.8-1,1.8-1.7,3-2.2c1.2-0.5,2.5-0.8,4-0.8c1.4,0,2.7,0.2,3.9,0.7c1.2,0.5,2.1,1.2,3,2.1c0.8,0.9,1.5,2,1.9,3.3
				c0.5,1.3,0.7,2.7,0.7,4.3V119.8z M224.8,118.2c-0.1-2.7-0.8-4.8-2.2-6.2c-1.4-1.4-3.1-2.2-5.4-2.2c-2.2,0-4,0.7-5.4,2.2
				c-1.4,1.5-2.1,3.5-2.2,6.2H224.8z"
                />
                <path
                  className="st5"
                  d="M239.6,108.3c0.7,0,1.4,0.1,2.1,0.3v1.8c-0.7-0.2-1.4-0.3-2.1-0.3c-1.8,0-3.2,0.6-4.1,1.7
				c-1,1.1-1.5,2.6-1.5,4.5v12.5h-1.9v-20.1h1.8l0.1,2.8c0.4-1,1.2-1.7,2.2-2.2C237.1,108.6,238.3,108.3,239.6,108.3z"
                />
              </g>
              <g className="st9">
                <path
                  className="st5"
                  d="M292.3,100.9v12.6h12.7v1.8h-12.7v13.5h-2v-15.3V98.9h17.1v1.9H292.3z"
                />
                <path
                  className="st5"
                  d="M318.4,108.3c0.7,0,1.4,0.1,2.1,0.3v1.8c-0.7-0.2-1.4-0.3-2.1-0.3c-1.8,0-3.2,0.6-4.1,1.7
				c-1,1.1-1.5,2.6-1.5,4.4v12.5h-1.9v-20.1h1.8l0.1,2.8c0.4-1,1.2-1.7,2.2-2.2C316,108.6,317.1,108.3,318.4,108.3z"
                />
                <path
                  className="st5"
                  d="M333.1,108.1c1.5,0,2.8,0.3,4,0.8c1.2,0.6,2.3,1.3,3.1,2.3c0.9,1,1.6,2.1,2.1,3.4c0.5,1.3,0.7,2.6,0.7,4.1
				c0,1.5-0.2,2.8-0.7,4.1c-0.5,1.3-1.2,2.4-2.1,3.4s-1.9,1.7-3.1,2.3c-1.2,0.6-2.6,0.8-4,0.8c-1.5,0-2.8-0.3-4-0.8
				c-1.2-0.6-2.3-1.3-3.1-2.3s-1.6-2.1-2.1-3.4c-0.5-1.3-0.7-2.6-0.7-4.1c0-1.5,0.2-2.8,0.7-4.1c0.5-1.3,1.2-2.4,2.1-3.4
				c0.9-1,1.9-1.7,3.1-2.3C330.2,108.3,331.6,108.1,333.1,108.1z M333.1,127.4c1.2,0,2.3-0.2,3.3-0.7c1-0.4,1.8-1.1,2.5-1.9
				c0.7-0.8,1.2-1.7,1.6-2.8c0.4-1.1,0.6-2.2,0.6-3.5s-0.2-2.4-0.6-3.5c-0.4-1.1-0.9-2-1.6-2.8c-0.7-0.8-1.5-1.4-2.5-1.9
				c-1-0.4-2.1-0.7-3.3-0.7c-1.2,0-2.3,0.2-3.3,0.7c-1,0.4-1.8,1.1-2.5,1.9c-0.7,0.8-1.2,1.7-1.6,2.8c-0.4,1.1-0.6,2.2-0.6,3.5
				s0.2,2.4,0.6,3.5c0.4,1.1,0.9,2,1.6,2.8c0.7,0.8,1.5,1.4,2.5,1.9C330.8,127.2,331.9,127.4,333.1,127.4z"
                />
                <path
                  className="st5"
                  d="M365.2,116.4v12.3h-1.9v-12.3c0-2.3-0.6-4-1.7-5c-1.2-1-2.6-1.6-4.3-1.6c-1.1,0-2.1,0.2-2.9,0.5
				c-0.8,0.4-1.5,0.9-2.1,1.5c-0.6,0.7-1,1.4-1.3,2.3c-0.3,0.9-0.5,1.9-0.5,2.9v11.5h-1.9v-20.1h1.7l0.1,3.1c0.6-1.1,1.6-2,2.8-2.7
				c1.3-0.7,2.7-1,4.3-1c2.2,0,4,0.7,5.5,2C364.5,111.4,365.2,113.5,365.2,116.4z"
                />
                <path
                  className="st5"
                  d="M379.1,110.2H375v18.5h-1.9v-18.5h-4.1v-1.6h4.1v-6.4h1.9v6.4h4.1V110.2z"
                />
                <path
                  className="st5"
                  d="M400.7,119.8h-17.1c0.2,2.5,1,4.3,2.3,5.6c1.4,1.3,3.1,1.9,5.2,1.9c1.7,0,3.1-0.4,4.3-1.2
				c1.2-0.8,2.1-1.9,2.7-3.3l1.7,0.8c-0.9,1.8-2.1,3.1-3.6,4.1c-1.5,0.9-3.3,1.4-5.2,1.4c-1.4,0-2.7-0.2-3.9-0.8
				c-1.2-0.5-2.1-1.2-3-2.1c-0.8-0.9-1.4-2-1.9-3.3c-0.4-1.3-0.7-2.7-0.7-4.3c0-1.5,0.2-3,0.7-4.3c0.4-1.3,1.1-2.4,1.9-3.4
				c0.8-0.9,1.8-1.7,3-2.2c1.2-0.5,2.5-0.8,4-0.8c1.4,0,2.7,0.2,3.9,0.7c1.2,0.5,2.2,1.2,3,2.1c0.8,0.9,1.5,2,1.9,3.3
				c0.5,1.3,0.7,2.7,0.7,4.3V119.8z M398.7,118.2c-0.1-2.7-0.8-4.8-2.2-6.2c-1.4-1.4-3.1-2.2-5.4-2.2s-4,0.7-5.4,2.2
				c-1.4,1.5-2.1,3.5-2.2,6.2H398.7z"
                />
                <path
                  className="st5"
                  d="M422.9,116.4v12.3h-1.9v-12.3c0-2.3-0.6-4-1.7-5c-1.2-1-2.6-1.6-4.3-1.6c-1.1,0-2.1,0.2-2.9,0.5
				c-0.8,0.4-1.5,0.9-2.1,1.5c-0.6,0.7-1,1.4-1.3,2.3c-0.3,0.9-0.5,1.9-0.5,2.9v11.5h-1.9v-20.1h1.7l0.1,3.1c0.6-1.1,1.6-2,2.8-2.7
				c1.3-0.7,2.7-1,4.3-1c2.2,0,4,0.7,5.5,2C422.1,111.4,422.9,113.5,422.9,116.4z"
                />
                <path
                  className="st5"
                  d="M445.4,128.7c-0.1-0.6-0.2-1.3-0.2-2c-0.1-0.7-0.1-1.4-0.1-2.1c-0.8,1.5-1.8,2.7-3.2,3.4
				c-1.4,0.8-2.9,1.2-4.7,1.2c-1.5,0-2.8-0.3-3.9-0.8c-1.1-0.5-2.1-1.3-2.9-2.2c-0.8-1-1.4-2.1-1.8-3.4c-0.4-1.3-0.6-2.7-0.6-4.2
				s0.2-2.9,0.6-4.2c0.4-1.3,1-2.4,1.8-3.4c0.8-0.9,1.8-1.7,2.9-2.2c1.1-0.5,2.4-0.8,3.9-0.8c1.8,0,3.3,0.4,4.7,1.2
				c1.4,0.8,2.5,1.9,3.2,3.5V98.4h1.9v25.9c0,0.9,0.1,1.7,0.1,2.4c0.1,0.7,0.1,1.4,0.2,1.9H445.4z M444.7,118.6
				c0-1.3-0.2-2.5-0.5-3.6c-0.3-1.1-0.8-2-1.5-2.8c-0.6-0.8-1.4-1.4-2.4-1.8c-0.9-0.4-2-0.7-3.2-0.7c-1.2,0-2.2,0.2-3.1,0.7
				c-0.9,0.4-1.7,1-2.3,1.8c-0.6,0.8-1.1,1.7-1.5,2.8c-0.3,1.1-0.5,2.3-0.5,3.6s0.2,2.5,0.5,3.6c0.3,1.1,0.8,2,1.4,2.8
				c0.6,0.8,1.4,1.4,2.3,1.8c0.9,0.4,2,0.6,3.2,0.6c1.2,0,2.2-0.2,3.2-0.6c0.9-0.4,1.7-1,2.4-1.8c0.6-0.8,1.1-1.7,1.5-2.8
				C444.6,121.1,444.7,119.9,444.7,118.6z"
                />
              </g>
            </g>
          </g>
        </svg>
      </NavLink>

      <Nav
        identify={"HeaderNav"}
        name={({ isActive }) => (isActive ? "Active" : "")}
      />
      <NavLink to="/">
        <svg
          version="1.1"
          id="Logo"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 170.1 53.9"
          xmlSpace="preserve"
        >
          <g>
            <path
              className="st0"
              d="M60.7,33.7c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.6c0,0,0,0,0,0.1c0,0.3-0.1,0.7-0.1,1c0,0.3-0.1,0.6-0.1,0.9
		c-0.1,0.7-0.3,1.4-0.4,2.1c-4.7,17.2-30.3,22.1-38.6,3.8c1.7-0.3,3.5-0.5,5.2-0.7c5.1,10.8,24.6,8.7,27.8-3.1v0c0,0,0,0,0,0
		c0.6-1.5,1.1-3.1,1.2-4.7c0-0.1,0-0.1,0-0.2c0-2.5-0.8-4.9-2.1-6.8c-2-2.7-5.2-4.2-9.1-2.4c-1.3,0-4.9,0-6.2,0
		c5.5-10.4,17.4-5.8,21.3,2.4C60.5,28.9,61,31.3,60.7,33.7z"
            />
            <path
              className="st0"
              d="M150.6,53.9H92.1c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.6,2.5-2.6l53.1-0.2c-0.3-2.3-0.9-5.9-2.2-10.2h0
		c-1.1-3.6-2.9-7.9-5.3-11.7c-8.6-13.8-22.9-21-42.5-21.6l0,19.2H88c-10.7-18-53.1-32.2-62-0.8c-1.7-0.2-3.4-0.4-5.1-0.7
		c8.9-34.7,54.4-24.8,69.3-4l0-18.9c32,0,46.8,13.5,53.5,26.7c2.2,4.1,3.7,8.1,4.8,11.7h0C150.9,48.4,150.4,49.6,150.6,53.9z"
            />
            <path
              className="st0"
              d="M125.6,30.6c0-0.3-0.1-0.5-0.2-0.8c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1
		c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.5-0.3-0.8
		c-0.1-0.3-0.3-0.6-0.4-0.9c-4.3-7.7-16-7.2-20.2,0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.3-0.2,0.5-0.3,0.8
		c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.4-0.1,0.6
		c0,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0.1,0.9c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6
		c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.6
		c4.2,7.7,16.1,7.2,20.2,0c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.6
		c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0.1-0.5c0-0.2,0-0.3,0-0.5
		C125.8,32.6,125.7,31.6,125.6,30.6z M118.5,35.9c-0.1,0.2-0.2,0.3-0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.5,0.2-1.1,0.4-1.7,0.4c-0.2,0-0.4,0-0.6,0h-0.2
		c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.2,0-0.3-0.1c-0.7-0.2-1.3-0.5-1.9-0.9c-0.1-0.1-0.2-0.2-0.4-0.3
		c-0.2-0.2-0.5-0.5-0.7-0.8c-0.7-0.8-1.1-2-1.1-3.4c0-2,0.8-3.4,2.1-4.3c0.2-0.2,0.4-0.3,0.7-0.5c0.1-0.1,0.2-0.1,0.3-0.2
		c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0
		c0.2,0,0.4,0,0.6,0s0.4,0,0.6,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.4,0.1,0.7,0.2,1,0.4
		c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4c1.2,0.9,2,2.3,2.1,4.3C119.7,34,119.2,35.1,118.5,35.9z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M170.1,32.6c0,3.2-2.5,5.8-5.7,5.9h-16c-1-3.6-2.5-7.6-4.8-11.7h20.6c3,0,5.5,2.3,5.8,5.3
		C170.1,32.2,170.1,32.4,170.1,32.6z"
            />
            <path
              className="st1"
              d="M103.8,37.9c0.1,0.2,0.2,0.4,0.3,0.6H60c0.2-0.7,0.3-1.4,0.4-2.1c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0.1-0.7,0.1-1
		c0,0,0,0,0-0.1c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1c0.3-2.4-0.2-4.8-1.2-6.9h44.6c-0.1,0.2-0.2,0.4-0.3,0.6
		c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1
		c0,0.1,0,0.2,0,0.2c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.5-0.1,0.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0.1,0.9c0,0,0,0.1,0,0.1
		c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0.1,0.6,0.2,0.8c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.7
		C103.6,37.4,103.7,37.7,103.8,37.9z"
            />
            <path
              className="st1"
              d="M124.3,38.5c1.2-2.1,1.8-4.7,1.3-7.8c-0.3-1.5-0.7-2.8-1.4-3.9h13.5c2.4,3.8,4.2,8.1,5.3,11.7H124.3z"
            />
            <path
              className="st1"
              d="M55.6,33.6c0,0.1,0,0.1,0,0.2c-0.1,1.6-0.6,3.2-1.2,4.7c0,0,0,0,0,0c-12.2-0.3-26.2,0.3-38.2,1.3L0,32.6
		l16.2-7.2c11.7,1,25.3,1.7,37.3,1.3C54.8,28.6,55.6,31.1,55.6,33.6z"
            />
          </g>
        </svg>
      </NavLink>
      <BurgerMenu />
    </header>
  );
};
