import PatPic from "../../assets/img/deco/PatPic.webp";
import PDF from "../../assets/img/PatrikDahl CV.pdf";
export const Galaxy = () => {

  return (
    <div id="Galaxy">
      <a id="CvBtt" href={PDF} target="_blank">
        <p> CV</p>
      </a>
      <img src={PatPic} alt="Profile picture" loading="lazy" />
      <svg
        version="1.1"
        id="WholeSVG"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1349.9 1303.9"
        xmlSpace="preserve"
      >
        <g id="Ring6">
          <path
            className="st0"
            d="M1296.4,552.3c-13.4-83.7-43-162.4-87.7-233.8c-43.2-68.9-98.7-128.2-165-176.1
                  C977.5,94.4,903.8,60.2,824.8,40.7C743,20.5,659,17.1,575.3,30.5C413.3,56.5,270,143,171.6,274.1c-6.3,8.4-9,18.8-7.6,29.2
                  c1.4,10.4,7,20,15.5,26.4l0.6,0.4c17.4,12.6,42.1,8.9,55-8.3c86-114.6,211.2-190.2,352.7-212.9c144.8-23.2,290.2,11.5,409.4,97.7
                  c119.1,86.2,197.6,213.4,220.8,358.2c7.2,44.7,8.9,89.7,5,134l0,0.4c-1,13.9,5.1,27,16.3,35.2c5.8,4.2,12.5,6.7,19.6,7.4l0.4,0
                  c1.1,0.1,2.3,0.1,3.4,0.1c9.4,0,18.3-3.3,25.5-9.4c8-6.8,13.1-16.6,14-26.9C1306.5,654.9,1304.6,603.3,1296.4,552.3z M227.6,316.2
                  L227.6,316.2C227.6,316.2,227.6,316.1,227.6,316.2C227.6,316.1,227.6,316.2,227.6,316.2z"
          />
          <path
            className="st0"
            d="M1170.4,974.2l-0.3-0.2c-17.5-12.9-42.3-9.2-55.3,8.1c-85.9,114.6-211.2,190.2-352.8,212.9
                  c-144.8,23.2-290.2-11.5-409.4-97.7c-119.2-86.2-197.6-213.5-220.8-358.3c-7.2-44.7-8.9-89.7-5.1-133.9c1.9-21.8-14.2-41.1-36-43
                  l-0.1,0c-3.2-0.3-6.5-0.2-9.7,0.4l0,0c-18.1,2.9-31.7,17.6-33.2,35.8c-4.4,50.8-2.4,102.4,5.8,153.3
                  c13.4,83.8,42.9,162.4,87.7,233.9c43.2,69,98.7,128.2,165,176.2c66.3,47.9,139.9,82.1,218.9,101.6c49.2,12.1,99.1,18.2,149.3,18.2
                  c33.3,0,66.8-2.7,100.2-8c162-26,305.4-112.5,403.7-243.7c6.4-8.5,9.1-19,7.5-29.6C1184.3,989.7,1178.8,980.5,1170.4,974.2z"
          />
        </g>
        <g id="Ring5">
          <path
            className="st1"
            d="M752,1078.8c-0.8-4.5-5.1-7.6-9.7-6.9c-109.4,17.6-219.7-7.6-310.6-71c-93-64.9-155.3-162.3-175.3-274.3
                  c-20-112,4.7-224.8,69.6-317.9c20-28.7,43.3-54.7,69.2-77.3c2.2-2,3.2-4.9,2.7-7.8c-0.3-1.5-0.9-2.9-1.9-4.1
                  c-1.5-1.7-3.6-2.7-5.7-2.9c-2.3-0.2-4.5,0.6-6.2,2c-26.9,23.5-51.2,50.5-72,80.3c-34.1,48.9-57.6,103.1-69.8,161
                  c-11.8,55.9-12.7,113-2.6,169.6c10.1,56.6,30.6,109.8,61.1,158.2c31.5,50.1,72.3,92.8,121.2,126.9c74.9,52.2,162.5,79.5,252.1,79.5
                  c23.5,0,47.2-1.9,70.8-5.7h0c2.2-0.4,4.2-1.6,5.5-3.4c1.3-1.8,1.9-4,1.5-6.3L752,1078.8z"
          />
          <path
            className="st1"
            d="M1110.1,574.3c-10.1-56.6-30.6-109.8-61.1-158.2c-31.5-50.1-72.3-92.8-121.2-126.9
                  c-94.6-65.9-209.3-92.2-323-73.9c-2.2,0.4-4.2,1.6-5.5,3.4c-1.3,1.8-1.8,4.1-1.5,6.3c0.8,4.6,5.1,7.7,9.7,7
                  c109.4-17.6,219.7,7.6,310.6,71c93.1,64.9,155.3,162.3,175.3,274.2c20,112-4.7,224.9-69.6,317.9c-20,28.7-43.3,54.7-69.1,77.2
                  c-3.5,3-3.9,8.4-0.8,11.9c0.5,0.5,1,1,1.5,1.4c1.5,1,3.2,1.5,4.8,1.5c2,0,4-0.7,5.5-2.1c27-23.5,51.2-50.6,71.9-80.3
                  c34.1-48.9,57.6-103.1,69.8-161C1119.3,688,1120.2,630.9,1110.1,574.3z"
          />
        </g>
        <g id="Ring4">
          <path
            className="st2"
            d="M608.8,281.3c30.5-5.4,61.3-7.1,91.7-5.1l0.2,0c6.4,0.4,12.3-2.5,15.9-7.7c1.9-2.7,3-5.8,3.2-9l0-0.2
                  c0.3-4.8-1.3-9.5-4.4-13.1c-3.1-3.6-7.7-5.9-12.4-6.2c-33.3-2.3-67.1-0.4-100.5,5.5c-54.8,9.8-106,30-152.3,60.2
                  C405.4,334.9,367.1,372,336.5,416c-30.7,44-52.3,92.7-64.1,144.7c-12.3,53.9-13.6,109-3.8,163.8c18.9,105.9,77.3,198.9,164.4,261.9
                  c3.1,2.3,6.8,3.4,10.6,3.4c0.9,0,1.9-0.1,2.8-0.2c4.7-0.7,9.1-3.4,11.9-7.3l0.2-0.3c5.6-8.1,3.6-19.3-4.3-25
                  c-79.5-57.5-132.7-142.3-150-238.9c-17.6-98.9,4.4-198.8,61.9-281.4C423.8,354.1,509.9,298.9,608.8,281.3z M450.6,962.1
                  L450.6,962.1C450.6,962.1,450.5,962.1,450.6,962.1C450.5,962.1,450.6,962.1,450.6,962.1z"
          />
          <path
            className="st2"
            d="M1081.3,579.5c-18.9-105.9-77.3-199-164.4-261.9c-3.9-2.9-8.8-4-13.6-3.2c-4.8,0.8-8.9,3.4-11.7,7.3l-0.1,0.1
                  c-5.7,8.1-3.9,19.4,4.2,25.2c79.5,57.4,132.7,142.3,150,238.9c17.6,98.9-4.4,198.8-61.9,281.4c-57.6,82.6-143.7,137.8-242.6,155.4
                  c-30.5,5.4-61.3,7.1-91.6,5.1c-4.8-0.3-9.5,1.2-13.2,4.4c-3.6,3.2-5.8,7.6-6.2,12.4c-0.1,1.5,0,3,0.2,4.4l0,0
                  c1.5,8.2,8.3,14.4,16.6,14.9c9.2,0.6,18.4,0.9,27.6,0.9c24.3,0,48.7-2.2,72.8-6.5c54.8-9.8,106-30,152.3-60.2
                  c44.7-29.1,83-66.2,113.6-110.2c30.7-44,52.2-92.7,64.1-144.7C1089.8,689.3,1091.1,634.2,1081.3,579.5z"
          />
        </g>
        <g id="Ring3">
          <g>
            <path
              className="st3"
              d="M940.3,656.6c-1.2,69.1-28.8,134.1-77.7,183c-51.8,51.8-119.7,77.6-187.7,77.6c-68,0-135.9-25.9-187.7-77.6
                      c-15.4-15.4-28.8-32.6-39.9-51c-0.3-0.5-0.4-1-0.2-1.6c0.1-0.5,0.5-1,1-1.3c0.3-0.2,0.7-0.3,1.1-0.3c0.7,0,1.4,0.4,1.8,1
                      c10.9,18.1,24.1,35,39.3,50.2c50.9,50.9,117.8,76.4,184.7,76.4c66.9,0,133.8-25.5,184.8-76.4c48.1-48.1,75.3-112.1,76.5-180.2
                      c0-1.2,1-2.1,2.1-2.1h0c0.6,0,1.1,0.2,1.5,0.6C940.1,655.5,940.4,656.1,940.3,656.6z"
            />
          </g>
          <g>
            <g>
              <path
                className="st3"
                d="M902.2,517.8c-0.1,0.1-0.3,0.2-0.4,0.3c-1,0.6-2.3,0.3-2.9-0.7c-10.9-18.1-24.1-35-39.2-50.2
                          C810.4,418,744.8,390.8,675,390.8c-69.9,0-135.5,27.1-184.7,76.4c-48.2,48.2-75.3,112.2-76.5,180.2c0,1.2-1,2.1-2.1,2.1
                          c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c1.2-69.1,28.8-134.1,77.7-183.1C539,412.5,607,386.6,675,386.6
                          c68,0,136,25.9,187.7,77.6c15.4,15.4,28.8,32.6,39.9,51C903,516.1,902.9,517.1,902.2,517.8z"
              />
            </g>
          </g>
        </g>
        <g id="Ring2">
          <path
            id="Earth1Ring2_1_"
            className="st4"
            d="M674.9,860.7c-115.1,0-208.8-93.6-208.8-208.7c0-17.2,2.1-34.2,6.2-50.5
                  c0.3-1.2,1.5-1.9,2.7-1.6c1.2,0.3,1.9,1.5,1.6,2.7c-4,16-6,32.7-6,49.5c0,112.6,91.6,204.3,204.3,204.3
                  c53.2,0,103.6-20.4,141.9-57.3c0.9-0.9,2.3-0.8,3.2,0.1c0.9,0.9,0.8,2.3-0.1,3.2C780.8,839.9,729.3,860.7,674.9,860.7z"
          />
          <path
            id="Earth1Ring2"
            className="st4"
            d="M875.4,704.2c-0.2,0-0.4,0-0.5-0.1c-1.2-0.3-1.9-1.5-1.6-2.7c4-16,6-32.7,6-49.4
                  c0-112.7-91.6-204.3-204.3-204.3c-53.3,0-103.7,20.4-141.9,57.3c-0.9,0.9-2.3,0.8-3.2-0.1c-0.9-0.9-0.8-2.3,0.1-3.2
                  c39.1-37.8,90.6-58.6,145-58.6c115.1,0,208.8,93.6,208.8,208.8c0,17.1-2.1,34.1-6.2,50.5C877.3,703.5,876.4,704.2,875.4,704.2z"
          />
        </g>
        <g id="Ring1">
          <path
            id="Earth1Ring1_1_"
            className="st4"
            d="M503.3,704.5c-0.8,0-1.6-0.5-1.9-1.4c-4.9-16.5-7.3-33.7-7.3-51.2
                  c0-12.1,1.2-24.2,3.6-35.9c7.7-38.3,28.1-73.6,57.6-99.6c33-29.2,75.5-45.3,119.7-45.3c54.3,0,105.3,24.1,139.8,66.1
                  c0.7,0.8,0.6,2-0.3,2.7c-0.8,0.7-2,0.6-2.7-0.3C778,498.6,728.1,475,674.9,475c-43.2,0-84.8,15.7-117.1,44.3
                  c-28.8,25.4-48.8,60.1-56.3,97.5c-2.3,11.5-3.5,23.3-3.5,35.1c0,17.1,2.4,33.9,7.2,50.1c0.3,1-0.3,2.1-1.3,2.4
                  C503.7,704.5,503.5,704.5,503.3,704.5z"
          />
          <path
            id="Earth1Ring1"
            className="st4"
            d="M674.9,832.8c-54.3,0-105.3-24.1-139.8-66.1c-0.7-0.8-0.6-2,0.3-2.7c0.8-0.7,2-0.6,2.7,0.3
                  c33.8,41.1,83.6,64.7,136.8,64.7c43.2,0,84.8-15.7,117.1-44.3c28.8-25.4,48.8-60,56.4-97.5c2.3-11.5,3.5-23.3,3.5-35.1
                  c0-17.1-2.4-33.9-7.2-50.1c-0.3-1,0.3-2.1,1.3-2.4c1-0.3,2.1,0.3,2.4,1.3c4.9,16.5,7.4,33.7,7.4,51.2c0,12.1-1.2,24.1-3.6,35.9
                  c-7.7,38.3-28.1,73.7-57.6,99.6C761.6,816.7,719.1,832.8,674.9,832.8z"
          />
        </g>
        <g id="GroupPluto2">
          <ellipse
            id="Pluto2"
            className="st4"
            cx="355.1"
            cy="652.2"
            rx="16.9"
            ry="16.8"
          />
        </g>
        <g id="GroupEarth2">
          <circle id="Earth2" className="st4" cx="802.6" cy="551.4" r="92" />
        </g>
        <g id="GroupGroupEarth3">
          <g id="GroupEarth3">
            <path
              id="Earth3Ring"
              className="st4"
              d="M495.1,832.5c-38.8,0-70.3-31.5-70.3-70.3c0-38.8,31.5-70.3,70.3-70.3
                      c38.8,0,70.3,31.5,70.3,70.3C565.4,801,533.9,832.5,495.1,832.5z M495.1,694.8c-37.2,0-67.4,30.2-67.4,67.4
                      c0,37.2,30.2,67.4,67.4,67.4c37.2,0,67.4-30.2,67.4-67.4C562.5,725.1,532.3,694.8,495.1,694.8z"
            />
            <circle
              id="Earth3Moon"
              className="st4"
              cx="447.3"
              cy="811.9"
              r="13.8"
            />
            <circle
              id="Earth3"
              className="st4"
              cx="495.1"
              cy="762.2"
              r="43.7"
            />
          </g>
        </g>
        <path
          className="st4"
          d="M674.9,488.7c-90,0-163.3,73.3-163.3,163.3c0,90,73.3,163.3,163.3,163.3c90,0,163.3-73.3,163.3-163.3
              C838.2,561.9,765,488.7,674.9,488.7z M674.9,805.6c-84.7,0-153.6-68.9-153.6-153.6c0-84.7,68.9-153.6,153.6-153.6
              c84.7,0,153.6,68.9,153.6,153.6C828.6,736.7,759.7,805.6,674.9,805.6z"
        />
        <g id="GroupPluto1">
          <ellipse
            id="Pluto1"
            className="st4"
            cx="995.3"
            cy="650.9"
            rx="32.8"
            ry="32.6"
          />
        </g>
        <g id="GroupDarkHole1">
          <path
            id="DarkHole1"
            className="st4"
            d="M985.3,692.9c-23.4-5.5-38-28.9-32.5-52.1c5.5-23.3,29-37.7,52.4-32.3
                  c23.4,5.5,38,28.9,32.5,52.1C1032.2,683.9,1008.7,698.3,985.3,692.9z M1004.1,613.5c-20.6-4.8-41.4,7.9-46.2,28.5
                  c-4.9,20.5,8,41.1,28.6,46c20.6,4.8,41.4-7.9,46.2-28.4C1037.6,638.9,1024.7,618.3,1004.1,613.5z"
          />
        </g>
        <g id="GroupDarkHole2">
          <path
            id="DarkHole2"
            className="st4"
            d="M352.7,675.6c-13-1.3-22.5-12.9-21.2-25.8s13-22.4,26-21.1c13,1.3,22.5,12.9,21.2,25.8
                  S365.7,676.9,352.7,675.6z M357.2,632.2c-11.1-1.1-21,6.9-22.1,17.9c-1.1,11,7,20.9,18,22c11.1,1.1,21-6.9,22.1-17.9
                  C376.3,643.2,368.2,633.4,357.2,632.2z"
          />
        </g>
      </svg>
    </div>
  );
};
